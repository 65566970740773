<form #form="ngForm">
	<div class="modal-header px-4">
		<h6 class="modal-title">
			Upload New Authorization
		</h6>

		<button type="button" class="btn-close" (click)="onClose()" [disabled]="saving">
			<span class="visually-hidden">&times;</span>
		</button>
	</div>

	<div class="modal-body">
		<fieldset [disabled]="saving">
			<input #fileBrowser type="file" class="d-none" />

			<div class="container">
				<div class="field-required row mb-3">
					<label class="col-7 col-form-label" for="isGeneric">
						Is generic?
					</label>

					<div class="col-17">
						<div class="form-check form-switch">
							<input class="form-check-input" type="checkbox" id="isGeneric" name="isGeneric" [(ngModel)]="model.isGeneric" role="switch" />
						</div>
					</div>
				</div>

				<div class="field-required row mb-3">
					<label class="col-7 col-form-label" for="authName">
						Authorization name
					</label>

					<div class="col-17">
						<input type="text" class="form-control" id="authName" name="authName" [(ngModel)]="model.authName" [required]="true" placeholder="e.g. Our Lady of the Lake" autocomplete="off" />
					</div>
				</div>

				<div class="field-required row mb-3">
					<label class="col-7 col-form-label" for="languageCode">
						Authorization language
					</label>

					<div class="col-8">
						<ng-select class="custom-ng-select"
								   id="languageCode"
								   name="languageCode"
								   [items]="languagesLookup"
								   [bindLabel]="'name'"
								   [bindValue]="'code'"
								   [(ngModel)]="model.languageCode"
								   [clearable]="false"
								   [multiple]="false"
								   [searchable]="false"
								   [loading]="languagesLookupLoading"
								   [required]="true"
								   [disabled]="saving"
								   loadingText="loading languages..."
								   placeholder="Authorization language">
						</ng-select>
					</div>
				</div>

				<div class="field-required row mb-3">
					<label class="col-7 col-form-label" for="dateSigned">
						Date signed
					</label>

					<div class="col-6">
						<input type="date" class="form-control" id="dateSigned" name="dateSigned" [(ngModel)]="model.dateSigned" [required]="true" />
					</div>
				</div>

				<div class="row">
					<div class="col-24">
						<div class="files-list" [class.empty]="selectedFile">
							<div class="files-list-file mb-0" *ngIf="selectedFile">
								<figure class="files-list-file-thumbnail">
									<img class="img-fluid" [src]="selectedFileThumbnail || null" alt="" />
								</figure>

								<div class="files-list-file-info">
									<div>
										{{ selectedFile.name }}
									</div>

									<div class="small gray-700">
										{{ selectedFile.type }}
										<div class="d-inline-block mx-1">•</div>
										{{ selectedFilePages }} {{ selectedFilePages == 1 ? 'page' : 'pages' }}
										<div class="d-inline-block mx-1">•</div>
										{{ selectedFile.size | fileSize }}
									</div>
								</div>

								<button class="btn btn-icon-only align-self-start px-1" [tooltip]="'Remove'" (click)="onRemoveFile()">
									<fa-icon [icon]="removeFileIcon"></fa-icon>
								</button>
							</div>

							<div class="files-list-placeholder" *ngIf="!selectedFile">
								<fa-icon class="fs-3 gray-500" [icon]="faFileLines"></fa-icon>
								<span class="text-nowrap">Drag and drop here or <a href="javascript:void(0);" (click)="onSelectFile()">select a file</a></span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</fieldset>
	</div>

	<div class="modal-footer bg-white px-4">
		<button type="button" class="btn btn-outline-primary" (click)="onClose()" [disabled]="saving">
			Cancel
		</button>

		<button type="button" class="btn btn-primary" (click)="onSave()" [disabled]="form.invalid || !selectedFile || saving">
			<fa-icon *ngIf="saving" class="me-2" [icon]="spinnerIcon" [spin]="true"></fa-icon>
			Save Authorization
		</button>
	</div>
</form>
