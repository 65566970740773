<div [ngClass]="class">
	<ng-container *ngIf="!valueEditing">
		<label class="form-control-static-value mb-0" [class.c-pointer]="!readOnly && !isPhone && !isTextArea" [class.form-control-static-empty]="isValueEmpty" (click)="!isTextArea && showEditingControl()">
			<!-- value prefix label -->
			<span *ngIf="valueLabel" class="form-label me-2" [ngClass]="nonEmptyLabelClass">{{ valueLabel }}</span>
			<!-- non-phone values -->
			<span [class.pe-1]="isValueEmpty" [class.d-none]="showValueLabelOnly" [class.textarea-spacing]="isTextArea" [ngClass]="nonEmptyLabelClass" [innerHTML]="formattedValue" *ngIf="!isPhone || isValueEmpty"></span>
			<!-- phone values -->
			<span [class.pe-1]="isValueEmpty" [class.d-none]="showValueLabelOnly" [ngClass]="nonEmptyLabelClass" *ngIf="isPhone && !isValueEmpty"><a href="tel:{{ (value?.length > 0 && value[0] === '+' ? value : '+1'+value) }}" [innerHTML]="formattedValue"(click)="$event.stopPropagation()"></a></span>

			<ng-container *ngIf="showEditHint && !readOnly">
				<button class="btn btn-sm btn-link btn-clear c-pointer py-0 px-1 ms-1 static-value-edit-action" type="button" placement="top" container="body" tooltip="Edit field" (click)="showEditingControl()">
					<fa-icon [icon]="faPencilAlt"></fa-icon>
				</button>
			</ng-container>
		</label>
	</ng-container>

	<ng-container *ngIf="valueEditing">
		<div class="input-group flex-nowrap" [ngClass]="inputSize ? ('input-group-' + inputSize) : ''">
			<!-- DROPDOWN INPUT -->
			<select *ngIf="isSelect" #selectInput class="form-control custom-select" [ngClass]="inputSize ? ('form-control-' + inputSize) : ''" id="selectInput" name="selectInput"
					[(ngModel)]="editedValue" [disabled]="saving">
				<option [ngValue]="v[selectId]" *ngFor="let v of selectValues">{{ v[selectDisplay] }}</option>
			</select>

			<!-- BOOLEAN DROPDOWN -->
			<select *ngIf="isBool" #selectInput class="form-control custom-select" [ngClass]="inputSize ? ('form-control-' + inputSize) : ''" id="selectInput" name="selectInput"
					[(ngModel)]="editedValue" [disabled]="saving">
				<option [ngValue]="'true'">Yes</option>
				<option [ngValue]="'false'">No</option>
			</select>

			<!-- LOTS OF POTENTIAL CONSOLIDATION BY COMBINING INPUTS -->
			<!-- PHONE INPUT -->
			<ng-container *ngIf="isPhone">
				<!-- us phone numbers -->
				<input #textInput *ngIf="isUSOnly" class="form-control" [ngClass]="inputSize ? ('form-control-' + inputSize) : ''" id="textInput" name="textInput"
				   [type]="type" [(ngModel)]="editedValue" [disabled]="saving" [placeholder]="placeholder"
				   [attr.autocomplete]="null" [textMask]="{ mask: phoneMask }">

				<!-- international phone numbers -->
				<div class="input-group" *ngIf="!isUSOnly">
					<div class="input-group-text px-1" *ngIf="selectedCountry"><span [class]="'flag-icon flag-icon-' + selectedCountry.toLowerCase()"></span></div>
					<select style="max-width: 110px;" class="form-select" [(ngModel)]="selectedCountry" (change)="onCountryChange(true)">
						<option *ngFor="let country of countries" [value]="country">							
							{{ country }} (+{{ getCountryCode(country) }})
						</option>
					</select>					
					<input class="form-control border-end-0 rounded-end-0" type="text" [textMask]="{ mask: phoneMask, guide: true, pipe: onEnsureStartsWithCountryCode }" [placeholder]="placeholder" [readonly]="readOnly" autocomplete="noautocomplete" [(ngModel)]="editedValue" />
				</div>
			</ng-container>
			

			<!-- CURRENCY INPUT -->
			<input #textInput *ngIf="isCurrency" class="form-control" [ngClass]="inputSize ? ('form-control-' + inputSize) : ''" id="textInput" name="textInput"
				   [type]="type" [(ngModel)]="editedValue" [disabled]="saving" [placeholder]="placeholder"
				   [textMask]="{ mask: currencyMask }">

			<!-- NUMBER INPUT -->
			<input #textInput *ngIf="isNumber" class="form-control" [ngClass]="inputSize ? ('form-control-' + inputSize) : ''" id="textInput" name="textInput"
				   [type]="type" [(ngModel)]="editedValue" [disabled]="saving" [placeholder]="placeholder"
				   [textMask]="{ mask: numberMask }">

			<!-- PERCENT INPUT -->
			<input *ngIf="isPercent" #textInput class="form-control" [ngClass]="inputSize ? ('form-control-' + inputSize) : ''" id="textInput" name="textInput"
				   [type]="type" [(ngModel)]="editedValue" [disabled]="saving" [placeholder]="placeholder"
				   [textMask]="{ mask: percentMask }">

			<!-- TEXT INPUT -->
			<input *ngIf="isText" #textInput class="form-control" [ngClass]="inputSize ? ('form-control-' + inputSize) : ''" id="textInput" name="textInput"
				   [type]="type" [(ngModel)]="editedValue" [disabled]="saving" [placeholder]="placeholder"
				   [attr.autocomplete]="isPassword ? 'new-password' : null" />

			<!-- DATE INPUT -->
			<input *ngIf="isDate" #textInput class="form-control" [ngClass]="inputSize ? ('form-control-' + inputSize) : ''" id="textInput" name="textInput"
				   type="date" [(ngModel)]="editedValue" [disabled]="saving"
				   [placeholder]="placeholder" />
				   <!-- TODO: REMOVE ONCE CONFIRMED NATIVE BROWSER DATE PICKER WORKS GREAT -->
				   <!-- [type]="type" [textMask]="dateMask" -->

			<!-- <bs-datepicker-inline></bs-datepicker-inline> -->
			<!-- <bs-datepicker-inline *ngIf="showCalendar" class="inline-edit-picker" (bsValueChange)="onCalendarSelect($event)"></bs-datepicker-inline> -->

			<!-- TEXTAREA INPUT -->
			<textarea *ngIf="isTextArea" #textAreaInput class="form-control" [ngClass]="inputSize ? ('form-control-' + inputSize) : ''" id="textAreaInput" name="textAreaInput"
					  [(ngModel)]="editedValue" [rows]="textAreaRowCount" [disabled]="saving" [placeholder]="placeholder" autosize></textarea>

			<!-- SAVE/CANCEL BUTTONS -->
			<!-- TODO: REMOVE ONCE CONFIRMED NATIVE BROWSER DATE PICKER WORKS GREAT -->
			<!-- <button *ngIf="isDate" class="btn btn-outline-secondary" type="button" (click)="showCalendar = !showCalendar" [disabled]="saving">
				<fa-icon [icon]="faCalendar" *ngIf="!showCalendar"></fa-icon>
				<fa-icon [icon]="faCalendarTimes" *ngIf="showCalendar"></fa-icon>
			</button> -->

			<button class="btn btn-outline-secondary" [ngClass]="inputSize ? ('btn-' + inputSize) : ''" type="button" tooltip="Save changes" (click)="saveChanges()" [disabled]="saving">
				<!-- <span class="d-flex justify-content-center align-items-center" style="width: 18px !important;">

				</span> -->
				<fa-icon *ngIf="!saving" [icon]="faCheck"></fa-icon>
					<fa-icon *ngIf="saving" [icon]="faSpinnerThird" [spin]="true"></fa-icon>
			</button>

			<button class="btn btn-outline-secondary" [ngClass]="inputSize ? ('btn-' + inputSize) : ''" type="button" tooltip="Cancel changes" (click)="cancelChanges()" [disabled]="saving">
				<fa-icon [icon]="faTimes"></fa-icon>
			</button>
		</div>
	</ng-container>
</div>
