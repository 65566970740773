import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { MomentModule } from 'ngx-moment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastrModule } from 'ngx-toastr';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { EditorModule } from '@tinymce/tinymce-angular';
import { TextMaskModule } from 'angular2-text-mask';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { ImageCropperModule } from 'ngx-image-cropper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { CurrencyMaskModule } from "ng2-currency-mask";

import { COMPONENTS } from './components';
import { DIRECTIVES } from './directives';
import { PIPES } from './pipes';

@NgModule({
	declarations: [
		COMPONENTS,
		DIRECTIVES,
		PIPES
	],
	imports: [
		CommonModule,
		NgOptimizedImage,
		FormsModule,
		RouterModule,
		HttpClientModule,
		DragDropModule,

		MomentModule,
		FontAwesomeModule,
		NgxScrollTopModule,
		ImageCropperModule,
		InfiniteScrollModule,
		NgxExtendedPdfViewerModule,
		EditorModule,
		NgSelectModule,
		TextMaskModule,
		ToastrModule.forRoot({ closeButton: true, positionClass: 'top-center' }),
		TooltipModule.forRoot(),
		CollapseModule.forRoot(),
		AccordionModule.forRoot(),
		BsDropdownModule.forRoot(),
		BsDatepickerModule.forRoot(),
		PopoverModule.forRoot(),
		ModalModule.forRoot(),
		TabsModule.forRoot(),
		TypeaheadModule.forRoot(),
		OwlDateTimeModule,
		OwlNativeDateTimeModule,
		CurrencyMaskModule
	],
	exports: [
		CommonModule,
		NgOptimizedImage,
		FormsModule,
		RouterModule,
		HttpClientModule,
		DragDropModule,

		MomentModule,
		FontAwesomeModule,
		NgxScrollTopModule,
		ImageCropperModule,
		InfiniteScrollModule,
		NgxExtendedPdfViewerModule,
		EditorModule,
		NgSelectModule,
		TextMaskModule,
		ToastrModule,
		TooltipModule,
		CollapseModule,
		AccordionModule,
		BsDropdownModule,
		BsDatepickerModule,
		PopoverModule,
		ModalModule,
		TabsModule,
		TypeaheadModule,
		OwlDateTimeModule,
		OwlNativeDateTimeModule,
		CurrencyMaskModule,

		COMPONENTS,
		DIRECTIVES,
		PIPES
	]
})
export class SharedModule {
}
