import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { AuthorizationCreate } from '@app/shared/models';
import { LookupService } from "@app/shared/services";
import { LookupValue } from '@app/settings/models';

import { Messages, LookupCodes } from '@app/constants';

import { faFileLines, faTimes, faTriangleExclamation, faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';
import { catchError, Subscription, tap, throwError } from 'rxjs';

@Component({
	templateUrl: 'authorization-create-modal.component.html',
	styleUrls: ['authorization-create-modal.component.scss']
})
export class AuthorizationCreateModalComponent implements OnInit, OnDestroy {
	removeFileIcon = faTimes;
	faFileLines = faFileLines;
	faTriangleExclamation = faTriangleExclamation;
	spinnerIcon = faSpinnerThird;

	@ViewChild('fileBrowser', { static: true })
	fileBrowser: ElementRef<HTMLInputElement>;

	model: AuthorizationCreate = new AuthorizationCreate();

	save: (authorization: AuthorizationCreate) => Promise<boolean>;
	saving: boolean = false;

	selectedFile: File;
	selectedFileSizeKB: number;
	selectedFileThumbnail: string;
	selectedFilePages: number;

	constructor(
		private lookupService: LookupService,
		private toastrService: ToastrService,
		private modalRef: BsModalRef
	) {
	}

	ngOnInit() {
		this.connectLanguages();

		this.fileBrowser.nativeElement.addEventListener('change', async (event: any) => {
			this.selectedFile = event.target.files[0];
			this.selectedFileSizeKB = this.selectedFile.size / 1024;
			this.selectedFileThumbnail = null;
			this.selectedFilePages = 1;

			this.model.authFile = new Uint8Array(await this.selectedFile.arrayBuffer());
			this.model.authFileName = this.selectedFile.name;
			this.model.authFileType = this.selectedFile.type;

			let reader = new FileReader();
			reader.addEventListener('load', async () => {
				if (this.model.authFileType == 'image/jpg' || this.model.authFileType == 'image/jpeg' || this.model.authFileType == 'image/png') {
					this.selectedFileThumbnail = reader.result as string;
				}
			}, false);
			reader.readAsDataURL(this.selectedFile);
		});
	}

	ngOnDestroy() {
		this.languagesSubscription?.unsubscribe();
	}

	languagesLookup: LookupValue[];
	languagesLookupLoading: boolean = true;
	languagesSubscription: Subscription;
	private connectLanguages() {
		this.languagesSubscription = this.lookupService.getLookup<LookupValue>(LookupCodes.Language)
			.pipe(
				tap(() => this.languagesLookupLoading = false),
				catchError(error => {
					this.languagesLookupLoading = false;
					this.toastrService.error(Messages.ErrorRetry, `Languages`);

					return throwError(() => error);
				})
			)
			.subscribe(languages => {
				this.languagesLookup = languages
			});
	}

	onSelectFile() {
		this.fileBrowser.nativeElement.click();
	}

	onRemoveFile() {
		this.selectedFile = null;
	}

	async onSave() {
		try {
			this.saving = true;

			let saved = await this.save(this.model);

			if (saved) {
				this.onClose();
			}
		} finally {
			this.saving = false;
		}
	}

	onClose() {
		this.modalRef.hide();
	}
}
