import { Component, OnInit } from '@angular/core';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { Authorization } from '@app/shared/models';
import { LookupService } from '@app/settings/services';
import { LookupValue } from '@app/settings/models';

import { Messages } from '@app/constants';

import { faTimes, faTriangleExclamation, faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';

@Component({
	templateUrl: 'authorization-request-modal.component.html',
	styleUrls: ['authorization-request-modal.component.scss']
})
export class AuthorizationRequestModalComponent implements OnInit {
	warningIcon = faTriangleExclamation;
	spinnerIcon = faSpinnerThird;

	loadingLanguages: boolean = false;
	languages: LookupValue[];

	model: Authorization;

	requestFunc: (authorization: Authorization) => Promise<boolean>;
	requesting: boolean = false;

	constructor(
		private lookupService: LookupService,
		private toastrService: ToastrService,
		private modalService: BsModalService,
		private modalRef: BsModalRef
	) {
	}

	ngOnInit() {
		this.onReloadLanguages();
	}

	async onReloadLanguages() {
		try {
			this.loadingLanguages = true;

			this.languages = await this.lookupService.getLookupValues('Language');
		} catch (error: any) {
			this.toastrService.error(Messages.ErrorRetry, `Failed to Load Languages`);
		} finally {
			this.loadingLanguages = false;
		}
	}

	async onRequest() {
		try {
			this.requesting = true;

			let requested = await this.requestFunc(this.model);

			if (requested) {
				this.modalService.setDismissReason('requested');
				this.onClose();
			}
		} finally {
			this.requesting = false;
		}
	}

	async onClose() {
		this.modalService.setDismissReason('close');
		this.modalRef.hide();
	}
}
