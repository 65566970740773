export const environment = {
	production: true,
	pwaEnabled: true,

	inactivity: {
		idle: 1800,
		timeout: 300,
		ping: 120
	},

	auth0: {
		domain: 'pointhealth-serviceportal-uat.us.auth0.com',
		clientID: 'GNm3w5sZLGTyk9oSKRZiykiLOaMdSa8q',
		// request access to this audience (api) at user authentication time
		audience: 'https://portalapiuat.pointhealthtech.com', // note, this is id, not url
		// redirectUri: window.location.origin,
		// useRefreshTokens: true,
		// cacheLocation: 'localstorage',
		// scope: 'offline_access',
	},

	api: {
		host: 'https://portalapiuat.pointhealthtech.com',
		other: ['https://portalapiuat.pointhealthtech.com',
			'https://pointhealth-portalapi-uat.azurewebsites.net']
	},

	sites: {
		memberWeb: 'https://appuat.pointhealthtech.com',
	},

	tiny: {
		// TODO: REPLACE WITH PROD PH KEY
		apiKey: 'wpf8b9ehbbxr74dvl7rqghkr66371jc4gw7mh0433cxhtqbv'
	},

	google: {
		staticMapsApiKey: 'AIzaSyDL2nDUzvjcmjXUQx-v6sJdcYF9_ppLEQQ',
		javaScriptMapsApiKey: 'AIzaSyBrymw05KO29N0loLeiEeEwOMV4oI8tOW8'
	},

	sentry: {
		dsn: 'https://98e1eb8df9854eedba4a20ead02498be@o493298.ingest.sentry.io/4504838276448256',
		tracePropagationTargets: ['localhost', 'portalapiuat.pointhealthtech.com', /^\//],
		tracesSampleRate: 0.5,
		environment: 'UAT'
	},

	// used for some one-off client-specific customizations
	clientCodes: {
		SederaClientCode: 'CL-62BF44DD'
	}
};
