<app-header trace="header" *ngIf="authService.isAuthenticated$ | async"></app-header>

<main class="main" trace="main-content">
	<div class="d-flex justify-content-center align-items-center vh-100"
		*ngIf="!(authService.isAuthenticated$ | async)">
		<div class="text-center pb-5">
			<img width="100" height="100" src="/assets/images/logo-icon.svg" />
			<div class="fs-4 text-muted mt-3 mb-5">Loading Workplace...</div>
		</div>
	</div>

	<router-outlet></router-outlet>
</main>

<app-footer trace="footer" *ngIf="authService.isAuthenticated$ | async"></app-footer>

<!-- scroll to top -->
<ngx-scrolltop backgroundColor="#232f42" trace="scrolltop"></ngx-scrolltop>

<!-- no internet toast -->
<div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
	<div #noInternetToast id="noInternetToast" class="toast bg-danger text-white" role="alert" data-bs-autohide="false"
		aria-live="assertive" aria-atomic="true">
		<div class="toast-body">
			<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-wifi-off"
				viewBox="0 0 16 16">
				<path
					d="M10.706 3.294A12.545 12.545 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c.63 0 1.249.05 1.852.148l.854-.854zM8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065 8.448 8.448 0 0 1 3.51-1.27L8 6zm2.596 1.404.785-.785c.63.24 1.227.545 1.785.907a.482.482 0 0 1 .063.745.525.525 0 0 1-.652.065 8.462 8.462 0 0 0-1.98-.932zM8 10l.933-.933a6.455 6.455 0 0 1 2.013.637c.285.145.326.524.1.75l-.015.015a.532.532 0 0 1-.611.09A5.478 5.478 0 0 0 8 10zm4.905-4.905.747-.747c.59.3 1.153.645 1.685 1.03a.485.485 0 0 1 .047.737.518.518 0 0 1-.668.05 11.493 11.493 0 0 0-1.811-1.07zM9.02 11.78c.238.14.236.464.04.66l-.707.706a.5.5 0 0 1-.707 0l-.707-.707c-.195-.195-.197-.518.04-.66A1.99 1.99 0 0 1 8 11.5c.374 0 .723.102 1.021.28zm4.355-9.905a.53.53 0 0 1 .75.75l-10.75 10.75a.53.53 0 0 1-.75-.75l10.75-10.75z" />
			</svg>
			<span class="ms-2">Your Internet connection appears to be down.</span>
		</div>
	</div>
</div>

<!-- app version -->
<div class="position-fixed bottom-0 start-0 p-3 text-muted">
	<small>v{{ appVersion?.build }} (ng v{{ angularVersion }})</small>
</div>

<!-- assistant chat -->
<div class="position-fixed bottom-0 end-0 p-5" *ngIf="isSystemAdministrator">
	<button style="width: 70px; height: 68px;" class="btn btn-primary rounded-circle py-2 px-3" type="button"
		data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">
		<fa-icon class="h3" [icon]="assistantIcon"></fa-icon>
	</button>
</div>

<div style="width: 45%;" id="offcanvasBottom" class="offcanvas offcanvas-end" tabindex="-1"
	aria-labelledby="offcanvasBottomLabel">
	<div class="offcanvas-header text-bg-primary">
		<h5 id="offcanvasBottomLabel" class="offcanvas-title"><fa-icon class="me-2" [icon]="assistantIcon"></fa-icon>
			Knowledge Base Assistant</h5>
		<button class="btn-close small text-white" type="button" data-bs-dismiss="offcanvas"
			aria-label="Close"></button>
	</div>
	<div class="offcanvas-body bg-light">
		<!-- welcome message -->
		<div class="card" *ngIf="!assistantSending && !assistantError && assistantMessages.length === 0">
			<div class="card-body"><app-empty
					message="Ask any questions about anything stored inside the Point Health Tech knowledge base (powered by Azure OpenAI)."></app-empty>
			</div>
		</div>
		<!-- chat messages -->
		<div class="row" [class.justify-content-end]="msg.role === 'user'" *ngFor="let msg of assistantMessages">
			<div class="col-23 col-sm-17" [class.text-end]="msg.role === 'user'">
				<div class="alert d-inline-block"
					[ngClass]="{ 'alert-light border-success text-dark': msg.role === 'assistant', 'alert-primary text-end': msg.role === 'user' }"
					role="alert">
					<fa-icon class="text-success me-2" [icon]="assistantIcon"
						*ngIf="msg.role === 'assistant'"></fa-icon>
					<span [innerHtml]="msg.message"></span>
					<fa-icon class="text-primary ms-2" [icon]="userIcon" *ngIf="msg.role === 'user'"></fa-icon>
				</div>
			</div>
		</div>
		<!-- loading assistance response placeholder -->
		<div class="row" *ngIf="assistantSending">
			<div class="col-23 col-sm-17">
				<div class="alert alert-light border-success text-success d-inline-block" role="alert">
					<fa-icon class="text-success me-3" [icon]="assistantIcon"></fa-icon>
					<app-spinner-wheel></app-spinner-wheel>
				</div>
			</div>
		</div>

		<!-- bottom aligned send input/button -->
		<div class="position-absolute bottom-0 start-0 end-0">
			<div class="text-end p-3"><button class="btn btn-outline-secondary" (click)="onClearAssistantMessages()">
					<fa-icon [icon]="clearHistoryIcon"></fa-icon>
				</button></div>
			<form class="p-3 bg-primary-subtle" (submit)="onSendAssistantMessage()">
				<fieldset [disabled]="assistantSending">
					<div class="alert alert-danger mb-3" role="alert" *ngIf="assistantError">
						Error sending request to assistant.<br />Please try again.
					</div>
					<div class="input-group">
						<input id="assistantRequestField" name="assistantRequestField" class="form-control" type="text"
							placeholder="Ask me anything..." autocomplete="off" [(ngModel)]="assistantMessageRequest">
						<button class="btn btn-primary" type="submit">
							<fa-icon [icon]="sendIcon" *ngIf="!assistantSending"></fa-icon>
							<app-spinner-wheel *ngIf="assistantSending"></app-spinner-wheel>
						</button>
					</div>
				</fieldset>
			</form>
		</div>
	</div>
</div>
