import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { File } from '@app/shared/models';
import { FilesService } from '@app/shared/services';
import { FileCreateModalComponent } from '@app/shared/components/file-create-modal/file-create-modal.component';
import { FileRenameModalComponent } from '@app/shared/components/file-rename-modal/file-rename-modal.component';
import { FileDeleteModalComponent } from '@app/shared/components/file-delete-modal/file-delete-modal.component';
import { FileViewerComponent } from '@app/shared/components/file-viewer/file-viewer.component';
import { AsideWidgetComponent } from '@app/shared/components';

import { Messages } from '@app/constants';

import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';

@Component({
	selector: 'app-files-widget',
	templateUrl: 'files-widget.component.html',
	styleUrls: ['files-widget.component.scss'],
	exportAs: 'filesWidget'
})
export class FilesWidgetComponent implements OnInit {
	faEllipsisVertical = faEllipsisVertical;

	saving: boolean = false;
	renameFileModalRef: BsModalRef<FileRenameModalComponent>;
	deleteFileModalRef: BsModalRef<FileDeleteModalComponent>;

	loadingFiles: boolean = false;
	loadingFilesError: boolean = false;
	files: File[];

	@Input()
	title: string = 'Files';

	@Input()
	load: () => Promise<File[]>;

	@Input()
	view: (fileID: number) => Promise<string>;

	@Input()
	save: (fileName: string, fileDate: string, fileType: string, fileBytes: Uint8Array) => Promise<File>;

	@Input()
	rename: (file: File) => Promise<File>;

	@Input()
	delete: (file: File) => Promise<void>;

	@ViewChild('filesAsideWidget', { static: false })
	filesAsideWidget: AsideWidgetComponent;

	constructor(
		private modalService: BsModalService,
		private toastrService: ToastrService,
		private filesService: FilesService
	) {
	}

	async ngOnInit() {
		this.onReloadFiles();
	}

	async onReloadFiles() {
		try {
			this.loadingFiles = true;
			this.loadingFilesError = false;

			this.files = await this.load();
		} catch (error: any) {
			console.error(error);
			this.loadingFilesError = true;
		} finally {
			this.loadingFiles = false;
		}
	}

	async onShowNewFileModal() {
		this.modalService.show<FileCreateModalComponent>(FileCreateModalComponent, {
			class: 'modal-lg',
			ignoreBackdropClick: true,
			animated: false,
			initialState: {
				save: async (fileName: string, fileDate: string, fileType: string, fileBytes: Uint8Array) => {
					try {
						await this.save(fileName, fileDate, fileType, fileBytes);

						this.onReloadFiles();

						return true;
					} catch (error: any) {
						this.toastrService.error(`Failed to save ${fileName} file`);
						return false;
					}
				}
			}
		});
	}

	async onViewFile(fileToView: File) {
		this.modalService.show<FileViewerComponent>(FileViewerComponent, {
			class: 'modal-xl',
			backdrop: 'static',
			ignoreBackdropClick: true,
			animated: false,
			initialState: {
				title: `View ${this.title}`,
				files: this.files.map(file => ({
					...file,
					_selected: file.fileID == fileToView?.fileID
				})),

				openFile: async (f, done) => {
					try {
						let fileToOpen = this.files.find(file => file.fileID == f.fileID);

						f._path = await this.view(fileToOpen.fileID);

						done();
					} catch (error: any) {
						console.error(error);
						this.toastrService.error(`Failed to generate access token to view '${fileToView.fileName}' file`);
					}
				},

				renameFile: async (f, done) => {
					try {
						let fileToRename = this.files.find(file => file.fileID == f.fileID);

						this.showRenameFileModal(fileToRename);

						this.renameFileModalRef.onHide.subscribe(async () => {
							if (this.renameFileModalRef.content.renamed) {
								done(this.renameFileModalRef.content.fileName);

								this.onReloadFiles();
							}
						});
					} catch (error: any) {
						console.error(error);
						this.toastrService.error(Messages.ErrorRetry, `Failed to Rename ${fileToView.fileName}`);
					}
				},

				deleteFile: async (f, done) => {
					let fileToDelete = this.files.find(file => file.fileID == f.fileID);

					this.showDeleteFileModal(fileToDelete);

					this.deleteFileModalRef.onHide.subscribe(async () => {
						if (this.deleteFileModalRef.content.deleted) {
							done();

							this.onReloadFiles();
						}
					});
				}
			}
		});
	}

	async onDownloadFile(file: File) {
		const downloadingToast = this.toastrService.warning(`Downloading ${file.fileName} file...`, null, { disableTimeOut: true, closeButton: false, progressBar: true });

		try {
			const fileURL = await this.view(file.fileID);
			await this.filesService.downloadFile(fileURL, file.fileName);
		} catch (error: any) {
		} finally {
			// hide toast after delay to allow for the download dialog to appear
			setTimeout(() => {
				downloadingToast.toastRef.close();
			}, 1000);
		}
	}

	async onRenameFile(file: File) {
		this.showRenameFileModal(file);

		this.renameFileModalRef.onHide.subscribe(async () => {
			if (this.renameFileModalRef.content.renamed) {
				this.onReloadFiles();
			}
		});
	}

	private showRenameFileModal(file: File) {
		this.renameFileModalRef = this.modalService.show(FileRenameModalComponent, {
			class: 'modal-md',
			ignoreBackdropClick: true,
			animated: false,
			initialState: {
				fileName: file.fileName,
				rename: async (newName: string) => {
					try {
						await this.rename({ ...file, fileName: newName });

						return true;
					} catch (error: any) {
						return false;
					}
				}
			}
		});
	}

	async onDeleteFile(file: File) {
		this.showDeleteFileModal(file);

		this.deleteFileModalRef.onHide.subscribe(async () => {
			if (this.deleteFileModalRef.content.deleted) {
				this.onReloadFiles();
			}
		});
	}

	private showDeleteFileModal(file: File) {
		this.deleteFileModalRef = this.modalService.show(FileDeleteModalComponent, {
			class: 'modal-md',
			ignoreBackdropClick: true,
			animated: false,
			initialState: {
				fileName: file.fileName,
				delete: async () => {
					try {
						await this.delete(file);

						return true;
					} catch (error: any) {
						return false;
					}
				}
			}
		});
	}
}
