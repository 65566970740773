import { CurrencyPipe, DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';

import { PhonePipe } from '@app/shared/pipes/phone.pipe';

import * as Sentry from "@sentry/angular-ivy";
import { conformToMask } from 'angular2-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'

import { getCountries, CountryCode, getCountryCallingCode } from 'libphonenumber-js';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

import {
	faPencilAlt,
	faCalendar,
	faCalendarTimes,
	faCheck,
	faTimes
} from '@fortawesome/pro-regular-svg-icons';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';

@Component({
	selector: 'app-inline-edit',
	templateUrl: './inline-edit.component.html',
	styleUrls: ['./inline-edit.component.scss']
})
@Sentry.TraceClassDecorator()
export class InlineEditComponent implements OnInit {
	faPencilAlt = faPencilAlt;
	faCalendar = faCalendar;
	faCalendarTimes = faCalendarTimes;
	faCheck = faCheck;
	faTimes = faTimes;
	faSpinnerThird = faSpinnerThird;

	phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
	dateMask = {
		name: 'Date',
		mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
		pipe: createAutoCorrectedDatePipe('mm/dd/yyyy'),
		keepCharPositions: true,
	};
	currencyMask = createNumberMask({
		allowDecimal: true,
		decimalLimit: 2,
		prefix: '$'
	});
	numberMask = createNumberMask({
		allowDecimal: true,
		decimalLimit: 2,
		prefix: ''
	});
	percentMask = createNumberMask({
		allowDecimal: true,
		requireDecimal: false,
		decimalLimit: 2,
		prefix: '',
		suffix: '%'
	});
	showCalendar: boolean = false;

	private _value: any;
	@Input('value')

	get value(): any {
		// if (this.isCurrency || this.isNumber) {
		// 	return this._value.toString().replace(/\D/g,'');
		// }
		return this._value;
	}
	set value(newValue: any) {
		if (this._value === newValue) { return; }
		this._value = newValue;
		this.editedValue = this._value;
		this.updateSelectValueName();
		if (this.isPhone && !this.isUSOnly) {
			this.onCountryChange(false);
		}
	}

	get formattedValue(): string {
		if (this.isBool && this.value) {
			return this.value == 'true' ? 'Yes' : 'No';
		} else if (this.isDate && this.value) {
			// NOTE: never use Date.parse as it is not consistent across browsers in terms of time zone
			return new DatePipe('en-US').transform(this.value, 'MM/dd/yyyy');
		} else if (this.isCurrency && this.value) {
			return new CurrencyPipe('en-US').transform(this.value);
		} else if (this.isNumber && this.value) {
			return new DecimalPipe('en-US').transform(this.value);
		} else if (this.isPercent && this.value) {
			return new PercentPipe('en-US').transform(this.value);
		} else if (this.isPhone && this.value) {
			return new PhonePipe().transform(this.value);
			// https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md#included-conformtomask
			// return conformToMask(this.value, this.phoneMask, {}).conformedValue;
		}

		return (this.isText || this.isPhone || this.isTextArea
			? (this.isPassword || this.isMasked
				? (this.value || '').replace(/./g, '*')
				: this.value)
			: this.selectValueName) || (this.value === 0 ? '0' : this.emptyLabel);
	}

	@Output()
	valueChange: EventEmitter<any> = new EventEmitter<any>();

	selectValueName: string;

	get isValueEmpty(): boolean {
		return !this.value && this.value !== 0 && !this.selectValueName;
	}

	@Input('input-size')
	inputSize: 'sm' | 'lg' | undefined;

	@Input('class')
	class: string;

	// @Input('valueEditing')
	// valueEditing: boolean;
	// @Output('valueEditingChange')
	// valueEditingChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Output('save')
	save: EventEmitter<InlineEditSaveEvent> = new EventEmitter<InlineEditSaveEvent>();

	@Input('saving')
	saving = false;

	@Input('is-select')
	isSelect: boolean;

	@Input('is-text')
	isText: boolean;

	@Input('is-date')
	isDate: boolean;

	@Input('is-phone')
	isPhone: boolean;
	@Input('is-us-only')
	isUSOnly: boolean = true;

	@Input('is-currency')
	isCurrency: boolean;

	@Input('is-number')
	isNumber: boolean;

	@Input('is-percent')
	isPercent: boolean;

	@Input('is-bool')
	isBool: boolean;

	@Input('type')
	type = 'text';

	@Input('has-escapes')
	hasEscapes = false;

	@Input('is-masked')
	isMasked = false;

	get isPassword(): boolean {
		return this.type === 'password';
	}

	@Input('is-textarea')
	isTextArea: boolean;
	@Input('textarea-rows')
	textAreaRowCount: number = 4;

	private _selectValues: any[];
	@Input('select-values')
	get selectValues(): any[] {
		return this._selectValues;
	}
	set selectValues(newValues: any[]) {
		this._selectValues = newValues;
		this.updateSelectValueName();
	}

	@Input('select-id')
	selectId = 'id';

	@Input('select-display')
	selectDisplay = 'name';

	@Input('value-label')
	valueLabel: string;

	@Input('show-value-label')
	showValueLabelOnly: boolean = false;

	@Input('placeholder')
	placeholder = '';

	@Input('empty-label')
	emptyLabel = 'empty';

	@Input('inline-block')
	@HostBinding('class.inline-block')
	inlineBlock = false;

	@Input('non-empty-label-class')
	nonEmptyLabelClass = '';

	@Input('show-edit')
	showEditHint = true;

	@Input('readOnly')
	readOnly = false;

	// TODO: RECONSILE WITH VALUEEDITING (SEEMS TO BE THE SAME)
	editedValue: any;

	@Input()
	get valueEditing(): boolean {
		return this._valueEditing;
	}
	set valueEditing(editing: boolean) {
		if (this.readOnly || this._valueEditing === editing) {
			return;
		}

		this._valueEditing = editing;
		this.valueEditingChange.next(this._valueEditing);
	}
	private _valueEditing = false;
	@Output()
	valueEditingChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	@ViewChild('selectInput', { static: false }) selectInput: ElementRef;
	@ViewChild('textInput', { static: false }) textInput: ElementRef;
	@ViewChild('textAreaInput', { static: false }) textAreaInput: ElementRef;

	private updateSelectValueName() {
		if (this.value == null || !this.selectValues || !this.isSelect) {
			this.selectValueName = null;
			return;
		}

		const valueObject = this.selectValues.find(value => `${value[this.selectId]}` === `${this.value}`);

		this.selectValueName = valueObject ? valueObject[this.selectDisplay] : null;
	}

	showEditingControl(event: Event = null) {
		this.valueEditing = true;
		setTimeout(() => {
			if (this.selectInput) {
				this.selectInput.nativeElement.focus();
			}
			if (this.textInput) {
				this.textInput.nativeElement.focus();
			}
			if (this.textAreaInput) {
				this.textAreaInput.nativeElement.focus();
			}
			// this.isDate ||
			if (this.isCurrency || this.isNumber || this.isPercent) {
				this.setMaskedEditingValue();
			}

			if (this.isPhone && !this.isUSOnly) {
				this.onCountryChange(false);
			}
		}, 100);
	}

	hideEditingControl() {
		this.valueEditing = false;
	}

	saveChanges() {
		let unmaskedValue = this.unmaskedValue();

		if (this.save.observers.length > 0) {
			// save old value in case need to undo on fail
			// const oldValue = cloneDeep(this.value);

			this.saving = true;

			this.save.emit({
				updatedValue: unmaskedValue,
				savedFunc: saved => {
					if (saved) {
						if (this.isBool || this.isDate || this.isCurrency || this.isNumber || this.isPhone) {
							// notify of value change
							this.valueChange.emit(unmaskedValue);
						} else {
							// update bound property
							this.value = isFinite(this.editedValue) ? +this.editedValue : this.editedValue;
							// notify of value change
							this.valueChange.emit(unmaskedValue);
						}
						// and close
						this.hideEditingControl();
					} else {
						// undo change
						// this.value = oldValue;
					}

					this.saving = false;
				}
			});
		} else {
			// unmask to set raw value
			if (this.isDate || this.isCurrency || this.isNumber || this.isPhone) {
				this.valueChange.emit(unmaskedValue);
			} else {
				this.value = isFinite(this.editedValue) ? +this.editedValue : this.editedValue;
				this.valueChange.emit(unmaskedValue);
			}
			this.hideEditingControl();
		}
	}
	cancelChanges() {
		// TODO: FIRE EVENT TO CANCEL
		this.hideEditingControl();
		// reset edited value
		this.editedValue = this.value;
	}

	ngOnInit() {
		this.updateSelectValueName();
	}

	constructor() { }


	// sometimes need to fire event to force new input through mask
	// https://github.com/text-mask/text-mask/issues/696
	// also used to highlight number masks
	setMaskedEditingValue() {
		if (this.textInput == undefined) return;
		const input = this.textInput.nativeElement;
		if (this.isDate) {
			// NOTE: never use Date.parse as it is not consistent across browsers in terms of time zone
			input.value = new DatePipe('en-US').transform(this.editedValue, 'MM/dd/yyyy');
		}
		// the number mask doesn't work if the initial character is '0' as it doesn't allow leading zeroes
		// by initially highlighting the whole number, this issue is avoided
		else if (this.isCurrency || this.isNumber) {
			var endOfText = (this.value == null || this.value <= 0) ? 2 : this.formattedValue.toString().length;
			input.setSelectionRange(1, endOfText);
		}
		input.dispatchEvent(new Event('input'));
	}

	// values passed to parent as same data type
	unmaskedValue() {
		if (this.isDate) {
			// NOTE: never use Date.parse as it is not consistent across browsers in terms of time zone
			return new DatePipe('en-US').transform(this.editedValue, 'yyyy-MM-dd');
		} else if (this.isCurrency || this.isNumber || this.isPercent) {
			return +this.editedValue?.replace("$", '').replace(",", "").replace("%", "");
		} else if (this.isPhone) {
			const unmasked = (this.isUSOnly ? '' : '+') + this.editedValue.replace(/[^\d]/g, '');
			return unmasked === ('+' + this.countryCode) ? null : unmasked;
			// return this.editedValue?.replace(/[^\d]/g, '');
		} else {
			return this.editedValue;
		}
	}

	onCalendarSelect(date: any) {
		this.showCalendar = false;
		this.editedValue = date;
		this.setMaskedEditingValue();
	}

	// international phone number support
	selectedCountry = 'US';
	countryCode = '+1';
	// only allow US and IN (india) for now
	countries = getCountries().filter(c => c === 'US' || c === 'IN');

	getCountryCode(country: string): string {
		return getCountryCallingCode(country as CountryCode);
	}

	onCountryChange(clearValue: boolean) {
		this.countryCode = getCountryCallingCode(this.selectedCountry as CountryCode);
		setTimeout(() => {
			if (clearValue ||
				(this.isUSOnly && this.editedValue?.startsWith('+')) ||
				(!this.isUSOnly && !this.editedValue?.startsWith('+' + this.countryCode))) {
				this.editedValue = this.isUSOnly ? '' : ('+' + this.countryCode);
			}
		}, 10);

		const phoneUtil = PhoneNumberUtil.getInstance();
		let exampleNumber: string;
		const examplePhoneNumber = phoneUtil.getExampleNumber(this.selectedCountry);
		if (this.selectedCountry === 'US') {
			exampleNumber = (this.isUSOnly ? '' : '+1 ') + phoneUtil.format(examplePhoneNumber, PhoneNumberFormat.NATIONAL);
		} else {
			exampleNumber = phoneUtil.format(examplePhoneNumber, PhoneNumberFormat.INTERNATIONAL);
		}

		this.placeholder = (this.isUSOnly ? '' : ('+' + this.countryCode)) +
			exampleNumber.replace('+' + this.countryCode, '').replace(/[\d]/g, '0');

		this.phoneMask = this.convertToMaskArray(exampleNumber);
	}

	onEnsureStartsWithCountryCode = (conformedValue: string) => {
		if (this.isUSOnly || !conformedValue) {
			return conformedValue;
		}

		const valuePart = conformedValue.substr(0, this.countryCode.length + 1);
		if (!('+' + this.countryCode).startsWith(valuePart)) {
			return false;
		}

		return conformedValue;
	}

	convertToMaskArray(formattedNumber: string): (string | RegExp)[] {
		const maskArray: (string | RegExp)[] = [];

		for (const char of formattedNumber) {
			if (char === '+') {
				maskArray.push('+');
			} else if (char === ' ') {
				maskArray.push(' ');
			} else if (isNaN(Number(char))) {
				maskArray.push(char);
			} else {
				maskArray.push(/\d/);
			}
		}
		return maskArray;
	}
}

export class InlineEditSaveEvent {
	updatedValue: any;
	savedFunc?: (saved: boolean) => void;
}
