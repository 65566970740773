<input type="text"
	   class="form-control"
	   [id]="id"
	   [name]="name"
	   [(ngModel)]="zipCode"
	   [textMask]="zipCodeMask"
	   [placeholder]="placeholder"
	   [required]="required"
	   [readonly]="readonly"
	   [disabled]="disabled"
	   autocomplete="off" />
