import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, ValidationErrors } from '@angular/forms';

import { CustomFormInput } from '../custom-form-input.abstract';

export const ZIPCODE_VALUE_ACCESSOR = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => ZipCodeInputComponent),
	multi: true
};

export const ZIPCODE_VALUE_VALIDATOR = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => ZipCodeInputComponent),
	multi: true,
};

@Component({
	selector: 'app-zipcode-input',
	templateUrl: 'input-zipcode.component.html',
	styleUrls: ['input-zipcode.component.scss'],
	providers: [ZIPCODE_VALUE_ACCESSOR, ZIPCODE_VALUE_VALIDATOR],
	exportAs: 'appZipCodeInput'
})
export class ZipCodeInputComponent extends CustomFormInput<string> {
	zipCodeMask = {
		keepCharPositions: true,
		mask: [/\d/, /\d/, /\d/, /\d/, /\d/],
	};

	override placeholder: string = 'Zip code';

	get zipCode(): string {
		return this.value
	}

	set zipCode(value: string) {
		let alphaNumericValue = value?.replace(/_/g, '') || '';

		if (this.value != alphaNumericValue) {
			this.value = alphaNumericValue;
			this.touch();
		}
	}

	override validate(control: FormControl): ValidationErrors | null {
		let alphaNumericValue = control.value?.replace(/_/g, '') || '';

		if (this.required && alphaNumericValue.length < 5) {
			return {
				length: true
			}
		}

		return null;
	}
}
