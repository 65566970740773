<div class="input-group">
	<div class="input-group-text px-1" *ngIf="selectedCountry"><span [class]="'flag-icon flag-icon-' + selectedCountry.toLowerCase()"></span></div>
	<select style="max-width: 110px;" class="form-select" [(ngModel)]="selectedCountry" (change)="onCountryChange(true)" *ngIf="!usOnly">
		<option *ngFor="let country of countries" [value]="country">
			{{ country }} (+{{ getCountryCode(country) }})
		</option>
	</select>
	<input type="text" class="form-control" [id]="id" [name]="name" [(ngModel)]="phoneNumber"
		[textMask]="{ mask: phoneMask, guide: true, pipe: onEnsureStartsWithCountryCode }" [placeholder]="placeholder" [required]="required" [readonly]="readonly"
		[disabled]="disabled" autocomplete="noautocomplete" />
</div>