import { APP_INITIALIZER, NgModule, ErrorHandler } from '@angular/core';
import { Router } from "@angular/router";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ServiceWorkerModule } from '@angular/service-worker';

import { AuthModule, AuthHttpInterceptor } from '@auth0/auth0-angular';
import * as Sentry from "@sentry/angular-ivy";
import { NgIdleModule } from '@ng-idle/core';

import { SharedModule } from '@app/shared';

import { TimeoutInterceptor, DEFAULT_TIMEOUT } from './timeout.interceptor';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';

import { environment } from '@app/environment';

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,

		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.pwaEnabled,
			// Register the ServiceWorker as soon as the application is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000'
		}),

		AuthModule.forRoot({
			domain: environment.auth0.domain,
			clientId: environment.auth0.clientID,

			audience: environment.auth0.audience,

			// More info and options can be found at
			// https://auth0.com/docs/quickstart/spa/angular/02-calling-an-api#provide-the-http-interceptor
			httpInterceptor: {
				allowedList: [{
					// match any request that starts with uri
					uri: `${environment.api.host}/*`
				}]
			}
		}),

		// component level performance tracking
		Sentry.TraceModule,

		SharedModule,

		AppRoutingModule,

		HttpClientModule,
		HttpClientJsonpModule,

		NgIdleModule.forRoot(),
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },

		// comfigure sentry error handling and tracing
		{ provide: ErrorHandler, useValue: Sentry.createErrorHandler() },
		{ provide: Sentry.TraceService, deps: [Router] },

		// require the trace service to be initialized before the app starts
		{ provide: APP_INITIALIZER, useFactory: () => () => { }, deps: [Sentry.TraceService], multi: true },

		// 30 second default timeout for all http requests (override with 'x-request-timeout' header)
		{ provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
		{ provide: DEFAULT_TIMEOUT, useValue: 30 * 1000 }
	],
	bootstrap: [
		AppComponent
	]
})
export class AppModule {
	constructor(
		private trace: Sentry.TraceService
	) { }
}
