import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';

import { lastValueFrom, catchError, map, of } from 'rxjs';

import { MemberDashboard, Member, MemberPlan, MemberDependent, MemberCase } from '@app/data/members/models';
import { File, Activity, Authorization, AuthorizationCreate } from '@app/shared/models';
import { handleHttpError, removeEmptyParams } from '@app/functions';
import { environment } from '@app/environment';

@Injectable({ providedIn: 'root' })
export class MembersService {
	protected get baseApiUrl() {
		return `${environment.api.host}/api/v1/members`;
	}

	constructor(
		private http: HttpClient
	) {
	}

	async getMemberDashboard(
		query: string,
		filterCode: string,
		clientID: number,
		planID: number,
		sortCode: string,
		sortDirection: string,
		pageNumber: number
	) {
		return lastValueFrom(
			this.getMemberDashboard$(query, filterCode, clientID, planID, sortCode, sortDirection, pageNumber)
		);
	}

	getMemberDashboard$(
		query: string,
		filterCode: string,
		clientID: number,
		planID: number,
		sortCode: string,
		sortDirection: string,
		pageNumber: number
	) {
		let options = {
			params: removeEmptyParams(new HttpParams().appendAll({
				query: query,
				filterCode: filterCode,
				clientID: clientID,
				planID: planID,
				sortCode: sortCode,
				sortDirection: sortDirection,
				page: pageNumber
			}))
		};

		return this.http
			.get<PagedDataModel<MemberDashboard>>(`${this.baseApiUrl}/dashboard`, options)
			.pipe(
				map((pagedData: PagedDataModel<MemberDashboard>) => {
					pagedData?.pageData?.forEach(member => {
						member.activePlans = member.plans.filter(plan => plan.isActive) || [];
						member.inactivePlans = member.plans.filter(plan => !plan.isActive) || [];
					});
					return pagedData;
				}),
				catchError(handleHttpError)
			);
	}

	async getMember(memberID: number) {
		return lastValueFrom(
			this.http.get<Member>(`${this.baseApiUrl}/${memberID}`).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async createMember(member: Member) {
		return lastValueFrom(
			this.http.post<Member>(`${this.baseApiUrl}`, member).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async updateMember(member: Member) {
		return lastValueFrom(
			this.http.put<Member>(`${this.baseApiUrl}/${member.clientMemberID}`, member).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async deleteMember(member: Member) {
		let options = {
			params: new HttpParams({
				fromObject: {
					version: member.version
				}
			})
		};

		return lastValueFrom(
			this.http.delete<void>(`${this.baseApiUrl}/${member.clientMemberID}`, options).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async getLinkedMembers(memberID: number) {
		return lastValueFrom(
			this.http
				.get<MemberDashboard[]>(`${this.baseApiUrl}/${memberID}/linked`)
				.pipe(catchError(handleHttpError))
		);
	}

	async getMemberPlans(memberID: number) {
		return lastValueFrom(
			this.http.get<MemberPlan[]>(`${this.baseApiUrl}/${memberID}/plans`).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async getMemberPlan(memberID: number, planID: number) {
		return lastValueFrom(
			this.http.get<MemberPlan>(`${this.baseApiUrl}/${memberID}/plans/${planID}`).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async createMemberPlan(memberID: number, plan: MemberPlan) {
		return lastValueFrom(
			this.http.post<MemberPlan>(`${this.baseApiUrl}/${memberID}/plans`, plan).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async updateMemberPlan(memberID: number, plan: MemberPlan) {
		return lastValueFrom(
			this.http.put<MemberPlan>(`${this.baseApiUrl}/${memberID}/plans/${plan.planID}`, plan).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async deactivateMemberPlan(clientMemberID: number, plan: MemberPlan) {
		let options = {
			params: new HttpParams({
				fromObject: {
					deactivationDate: plan.terminationDate,
					version: plan.version,
				}
			})
		};

		return lastValueFrom(
			this.http.put<MemberPlan>(`${this.baseApiUrl}/${clientMemberID}/plans/${plan.clientMemberPlanID}/deactivate`, null, options).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async deleteMemberPlan(memberID: number, plan: MemberPlan) {
		let options = {
			params: new HttpParams({
				fromObject: {
					version: plan.version,
				}
			})
		};

		return lastValueFrom(
			this.http.delete<void>(`${this.baseApiUrl}/${memberID}/plans/${plan.planID}`, options).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async getMemberDependents(memberID: number) {
		return lastValueFrom(
			this.http
				.get<MemberDependent[]>(`${this.baseApiUrl}/${memberID}/dependents`)
				.pipe(catchError(handleHttpError))
		);
	}

	async makeMemberDependent(
		dependentMemberPlanID: number,
		primaryMemberID: number,
		planID: number,
		relationshipTypeCode: string
	) {
		let options = {
			params: removeEmptyParams(new HttpParams({
				fromObject: {
					planID: planID,
					relationshipTypeCode: relationshipTypeCode
				}
			}))
		};

		return lastValueFrom(
			this.http
				.post<void>(`${this.baseApiUrl}/${primaryMemberID}/dependents/${dependentMemberPlanID}`, null, options)
				.pipe(catchError(handleHttpError))
		);
	}

	async getMemberDependent(memberID: number, dependentID: number) {
		return lastValueFrom(
			this.http.get<MemberDependent>(`${this.baseApiUrl}/${memberID}/dependents/${dependentID}`).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async getMemberCases(memberID: number) {
		return lastValueFrom(
			this.http.get<MemberCase[]>(`${this.baseApiUrl}/${memberID}/cases`).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async getMemberCase(memberID: number, dependentID: number) {
		return lastValueFrom(
			this.http.get<MemberCase>(`${this.baseApiUrl}/${memberID}/cases/${dependentID}`).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async getMemberAuthorizations(memberID: number): Promise<Authorization[]> {
		return lastValueFrom(
			this.http.get<Authorization[]>(`${this.baseApiUrl}/${memberID}/auths`).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async createMemberAuthorization(memberID: number, authorization: AuthorizationCreate) {
		let fileBlob = new Blob([authorization.authFile.buffer], { type: authorization.authFileType });
		let formData = new FormData();

		formData.append('isGeneric', authorization.isGeneric ? 'true' : 'false');
		formData.append('authName', authorization.authName);
		formData.append('languageCode', authorization.languageCode);
		formData.append('dateSigned', authorization.dateSigned);
		formData.append('authFile', fileBlob, `${authorization.authFileName}`);

		return lastValueFrom(
			this.http.post<Authorization>(`${this.baseApiUrl}/${memberID}/auths`, formData).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async requestMemberAuthorization(memberID: number, authorization: Authorization) {
		return lastValueFrom(
			this.http.get<Authorization>(`${this.baseApiUrl}/${memberID}/auths/${authorization.authorizationID}/request`).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async deleteMemberAuthorization(memberID: number, authorization: Authorization) {
		let options = {
			params: {
				version: authorization.version
			}
		};

		return lastValueFrom(
			this.http.delete<void>(`${this.baseApiUrl}/${memberID}/auths/${authorization.authorizationID}`, options).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async getMemberFiles(memberID: number) {
		return lastValueFrom(
			this.http.get<File[]>(`${this.baseApiUrl}/${memberID}/files`).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async getMemberFileURL(memberID: number, fileID: number) {
		return lastValueFrom(
			this.http.get<string>(`${this.baseApiUrl}/${memberID}/files/${fileID}/view`).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async createMemberFile(memberID: number, fileName: string, fileDate: string, fileType: string, bytes: Uint8Array) {
		let fileBlob = new Blob([bytes.buffer], { type: fileType });
		let formData = new FormData();

		formData.append('fileName', fileName);
		formData.append('fileDate', fileDate);
		formData.append('file', fileBlob, `${fileName}`);

		return lastValueFrom(
			this.http.post<File>(`${this.baseApiUrl}/${memberID}/files`, formData).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async renameMemberFile(memberID: number, file: File) {
		let options = {
			params: {
				name: file.fileName,
				version: file.version
			}
		};

		return lastValueFrom(
			this.http.put<File>(`${this.baseApiUrl}/${memberID}/files/${file.fileID}/rename`, null, options).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async deleteMemberFile(memberID: number, file: File) {
		let options = {
			params: {
				version: file.version
			}
		};

		return lastValueFrom(
			this.http.delete<void>(`${this.baseApiUrl}/${memberID}/files/${file.fileID}`, options).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async getMemberActivity(memberID: number, pageNumber: number = 1) {
		let options = {
			params: {
				page: pageNumber
			}
		};

		return lastValueFrom(
			this.http.get<PagedDataModel<Activity>>(`${this.baseApiUrl}/${memberID}/activity`, options).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async createMemberActivity(memberID: number, activity: Activity) {
		return lastValueFrom(
			this.http.post<Activity>(`${this.baseApiUrl}/${memberID}/activity`, activity).pipe(
				catchError(handleHttpError)
			)
		);
	}
}
