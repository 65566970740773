import { Component, OnInit } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { faMemoCircleInfo }  from '@fortawesome/pro-solid-svg-icons';

import { Authorization } from '@app/shared/models';

@Component({
    templateUrl: 'authorization-digital-modal.component.html',
    styleUrls: ['authorization-digital-modal.component.scss']
})
export class AuthorizationDigitalModalComponent implements OnInit {
    deviceInfoIcon = faMemoCircleInfo;

    private _model: Authorization;
    get model(): Authorization {
        return this._model;
    }
    set model(value: Authorization) {
        this._model = value;
        if (this._model) {
            this.deviceInfoObject = JSON.parse(this._model.deviceInfo);
        }
    }
    deviceInfoObject: any | null = null;

    constructor(
        private modalRef: BsModalRef,
    ) {
    }

    ngOnInit() {
    }

    onClose() {
        this.modalRef.hide();
    }
}
