import { UserProfile } from './models/user-profile.model';
import {
	Lookup,
	ClientLookup,
	PlanLookup,
	PortalUserLookup,
	CaseLookup,
	BillLookup,
	ClientPlanMemberLookup,
	TaskTemplatesLookup
} from './models/lookup.models';
import { SystemSetting } from './models/settings.models';
import { InlineEditSaveEvent } from './components/inline-edit/inline-edit.component';
import { SwitchToggleEvent } from './components/switch/switch.component';
import { Breadcrumb } from './models/breadcrumb.model';
import { Task } from './models/task.model';
import { Activity } from './models/activity.model';
import { File } from './models/file.model';
import { Authorization, AuthorizationCreate } from './models/authorization.models';
import { AssistantMessageModel, AssistantMessageCitationModel } from './models/assistant.models';

export { UserProfile } from './models/user-profile.model';
export {
	Lookup,
	ClientLookup,
	PlanLookup,
	PortalUserLookup,
	CaseLookup,
	BillLookup,
	ClientPlanMemberLookup,
	TaskTemplatesLookup
} from './models/lookup.models';
export { SystemSetting } from './models/settings.models';
export { InlineEditSaveEvent } from './components/inline-edit/inline-edit.component';
export { SwitchToggleEvent } from './components/switch/switch.component';
export { Breadcrumb } from './models/breadcrumb.model';
export { Task } from './models/task.model';
export { Activity } from './models/activity.model';
export { File } from './models/file.model';
export { Authorization, AuthorizationCreate } from './models/authorization.models';
export { AssistantMessageModel, AssistantMessageCitationModel } from './models/assistant.models';

export const MODELS: any[] = [
	UserProfile,
	Lookup,
	ClientLookup,
	PlanLookup,
	PortalUserLookup,
	CaseLookup,
	BillLookup,
	ClientPlanMemberLookup,
	TaskTemplatesLookup,
	SystemSetting,
	InlineEditSaveEvent,
	SwitchToggleEvent,
	Breadcrumb,
	Task,
	Activity,
	File,
	Authorization,
	AuthorizationCreate,
	AssistantMessageModel, AssistantMessageCitationModel
];
