<header class="header sticky-top">
	<nav class="navbar navbar-expand-lg navbar-light bg-white header-menu">
		<div class="container">
			<a class="navbar-brand" routerLink="/">
				<img src="/assets/images/logo-pointhealthtech.svg" alt="Point Healthtech" />
			</a>

			<button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#menu"
				aria-controls="menu" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>

			<div id="menu" class="collapse navbar-collapse">
				<ul class="navbar-nav ms-auto mb-2 mb-lg-0">
					<li class="nav-item">
						<a class="nav-link" routerLink="/">Workplace</a>
					</li>

					<li class="nav-item">
						<a class="nav-link" routerLink="/navigation">Navigation</a>
					</li>

					<li class="nav-item">
						<a class="nav-link" routerLink="/knowledge-base">Knowledge</a>
					</li>

					<!-- <li class="nav-item">
						<a class="nav-link" routerLink="/data">Data</a>
					</li> -->
					<li class="nav-item dropdown">
						<a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
							aria-expanded="false">
							Data
						</a>
						<ul class="dropdown-menu">
							<li><a class="dropdown-item" routerLink="/data/tasks">Tasks</a></li>
							<li><a class="dropdown-item" routerLink="/data/cases">Cases</a></li>
							<li><a class="dropdown-item" routerLink="/data/bills">Bills</a></li>
							<li><a class="dropdown-item" routerLink="/data/members">Members</a></li>
							<li><a class="dropdown-item" routerLink="/data/clients">Clients</a></li>
						</ul>
					</li>

					<li class="nav-item">
						<a class="nav-link" routerLink="/surveys">Surveys</a>
					</li>

					<li class="nav-item">
						<a class="nav-link" routerLink="/analytics">Analytics</a>
					</li>

					<li class="nav-item" *ngIf="isSystemAdministrator">
						<a class="nav-link" routerLink="/settings">Settings</a>
					</li>
				</ul>

				<div class="navbar-nav ms-4 mb-2 mb-lg-0">
					<ng-container *ngIf="!isAuthenticated">
						<a class="nav-link mx-3" routerLink="/account/sign-in">
							Sign In
						</a>
					</ng-container>

					<ng-container *ngIf="isAuthenticated">
						<ng-container *ngIf="profile">
							<div class="dropdown user-menu">
								<a class="nav-link rounded-circle" [class.text-bg-light]="profile?.portalUserImageURL"
									[class.text-bg-dark]="!profile?.portalUserImageURL" href="javascript:void(0);"
									role="button" data-bs-toggle="dropdown" aria-expanded="false">
									<img [src]="profile?.portalUserImageURL" [alt]="profile?.initials"
										*ngIf="profile?.portalUserImageURL" />
									<span class="fw-light px-1" *ngIf="!profile?.portalUserImageURL">{{
										profile?.initials }}</span>
								</a>

								<ul class="dropdown-menu dropdown-menu-end">
									<li>
										<a class="dropdown-item" routerLink="/account/profile">
											Profile
										</a>
									</li>

									<li>
										<hr class="dropdown-divider" />
									</li>

									<li>
										<a class="dropdown-item" routerLink="/account/sign-out">
											Sign Out
										</a>
									</li>
								</ul>
							</div>
						</ng-container>
					</ng-container>
				</div>
			</div>
		</div>
	</nav>
</header>
